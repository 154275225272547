import { Link } from "react-router-dom";
import { Card, Group, Title, Grid, Text, Center, Loader, Badge, SimpleGrid, Stack, Paper, Button, Checkbox, Menu, ActionIcon, Tooltip, Divider } from "@mantine/core"
import { IconAdjustmentsHorizontal, IconCheckbox, IconDotsCircleHorizontal, IconDotsVertical, IconExternalLink, IconPencil, IconSquare } from "@tabler/icons-react"
import moment from "moment"
import { useProgramsQuery, useProgramSessionsByIdQuery as useProgramSessionsByProgramIdQuery, useProgramActivitiesQuery } from "../../api/program.api";
import { formatDateRange, getParticipantsForSession } from "../../functions/programregistration.functions";
import { useProgramSessionEnrollmentsByRegistrationIdQuery } from "../../api/programsession.api";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import { useProgramActivitySessionEnrollmentsByRegistrationIdQuery, useProgramActivitySessionsByProgramIdQuery } from "../../api/programactivitysession.api";
import SessionActivityEditor from "./SessionActivityEditor";

const SessionsView = () => {
	// const { registrationId } = useParams<ViewRegistationPageParams>();
	// const { data: registration, isLoading: isLoadingRegistration } = useRegistrationByIdQuery(Number(registrationId));
	const { programRegistration, isLoading: isLoadingRegistration, isDeleted, toggleProgramSessionEnrollment, toggleProgramSessionWaitList, enrollAllProgramSessions, unEnrollAllProgramSessions, getRegistrationEnrollmentForSession } = useProgramRegistrationState();
	const { data: programs, isLoading: isLoadingPrograms } = useProgramsQuery();
	const { data: programSessionEnrollments, isLoading: isLoadingProgramSessionEnrollments } = useProgramSessionEnrollmentsByRegistrationIdQuery(programRegistration?.Id ?? 0);
	const { data: programSessions, isLoading: isLoadingProgramSessions } = useProgramSessionsByProgramIdQuery(programRegistration?.Data.ProgramId ?? 0);
	const { data: programActivities } = useProgramActivitiesQuery();
	const { data: programActivitySessions, isLoading: isLoadingProgramActivitySessions } = useProgramActivitySessionsByProgramIdQuery(programRegistration?.Data.ProgramId ?? 0, programRegistration.Data.DateFrom ?? new Date().toISOString(), programRegistration.Data.DateTo ?? new Date().toISOString());
	
	const program = programs?.find(p => p.Id === (programRegistration?.Data.ProgramId ?? 0));

	const getProgramActivity = (programActivitySessionId: number) => {
		let programActivitySession = programActivitySessions?.find(p => p.Id === programActivitySessionId);
		
		if (!programActivitySession)
			return undefined;
		return programActivities?.find(p => p.Id === programActivitySession?.ActivityId);
	}

	// const enrolledProgramSessionIds = programSessionEnrollments?.map(e => e.ProgramSessionId) ?? [];
	const programSessionsForRegistration = programSessions?.filter(s => moment(s.StartDate).isSameOrAfter(moment(programRegistration.Data.DateFrom)) && moment(s.EndDate).isSameOrBefore(moment(programRegistration.Data.DateTo)));//?.filter(s => enrolledProgramSessionIds.includes(s.Id));

	const isLoading = isLoadingRegistration || isLoadingPrograms || isLoadingProgramSessions || isLoadingProgramSessionEnrollments;

	const showSingleColumn = (programRegistration?.Data.Participants.length ?? 0) > 2 || (programSessionsForRegistration?.length ?? 0) === 1;

	return <>
		<Card shadow="lg" withBorder mb="sm">
			<Group position="apart" noWrap mb={0}>
				<Group spacing="xs" noWrap>
					<IconAdjustmentsHorizontal />
					<Title order={3} truncate>Sessions for {program?.Title}</Title>
				</Group>
				{!isLoading &&
				<Menu shadow="lg">
					<Menu.Target>
						<Tooltip label="Actions" position="top" withinPortal>
							<ActionIcon disabled={isDeleted} variant="subtle" color="primary" radius="xl" mt={0} sx={{ alignSelf: 'flex-start' }}><IconDotsCircleHorizontal /></ActionIcon>
						</Tooltip>
					</Menu.Target>
					<Menu.Dropdown>
						<Menu.Item onClick={() => enrollAllProgramSessions(programSessionsForRegistration ?? [])} icon={<IconCheckbox />}>Select All Sessions</Menu.Item>
						<Menu.Item onClick={() => unEnrollAllProgramSessions(programSessionsForRegistration ?? [])} icon={<IconSquare />}>Unselect All Sessions</Menu.Item>

					</Menu.Dropdown>
				</Menu>
				}
			</Group>
			{programRegistration &&
			<Text size="xs" mb="md">{formatDateRange(programRegistration.Data.DateFrom, programRegistration.Data.DateTo)}</Text>
			}	
			{isLoading && <Center h={150} mx="auto">
				<Loader />
			</Center>}
			<SimpleGrid breakpoints={[{minWidth: "xs", cols: 1}, { minWidth: "sm", cols: showSingleColumn ? 1 : 2 }, { minWidth: "lg", cols: showSingleColumn ? 1 : 2 }]}>
			{!isLoading && programSessionsForRegistration?.map((programSession, index) => {
				let participants = getParticipantsForSession(programRegistration!, programSession.Id);
				let enrollment = programSession.Enrollment + getRegistrationEnrollmentForSession(programSession, programSessionEnrollments ?? []);

				return <Paper p="sm" withBorder key={index}>
					{/* {moment(programSession.StartDate).isSame(moment(programSession.EndDate), "date") && <> */}
					<Group position="apart" align="flex-start" noWrap>
						{/* <Text color="dimmed" size="sm">Session</Text> */}
						<Group position="left" spacing={3}>
							{/* <Link to={`/calendar/session/${programSession.Id}`} style={{ textDecoration: 'none', color: 'unset' }}> */}
							{moment(programSession.StartDate).isSame(moment(programSession.EndDate), "date") && <>
							<Text size="xs"><Text weight="bold" size="sm">{moment(programSession.StartDate).format("LL")}</Text>{moment(programSession.StartDate).format("h:mma")} - {moment(programSession.EndDate).format("h:mma")}</Text>
							</>}
							{moment(programSession.StartDate).isSame(moment(programSession.EndDate), "date") === false && <>
							<Text size="xs">{moment(programSession.StartDate).format("LL")}, {moment(programSession.StartDate).format("h:mma")} - {moment(programSession.EndDate).format("LL")}, {moment(programSession.EndDate).format("h:mma")}</Text>
							</>}
							{/* </Link> */}
							
						</Group>
						<Group spacing={2}>
							<Badge color={programSession.Limit - enrollment <= 0 ? 'red' : ((programSession.Limit - enrollment) / programSession.Limit) < .2 ? 'orange' : 'green'}>{enrollment}/{programSession.Limit}</Badge>
							<Tooltip label="Open Session">
								<ActionIcon color="primary" component={Link} to={`/calendar/session/${programSession.Id}`} target="_blank" variant="subtle" mt={-4} sx={{ alignSelf: 'flex-start' }}>
									<IconExternalLink />
								</ActionIcon>
							</Tooltip>
						</Group>
					</Group>
				
					<Grid mt={0}>
						<Grid.Col span={12}>
							{/* <Text color="dimmed" size="sm" align="center">Participants:</Text> */}
							<Divider label="Participants" labelPosition="center" labelProps={{ "color": "dimmed" }} />
							{programRegistration.Data.Participants.length == 0 &&
							<Center>
								<Text color="dimmed" size="sm" mt="md">No participants added yet</Text>
							</Center>
							}
							<SimpleGrid cols={programRegistration.Data.ProgramSessions.length > 1 ? 2 : 3} mt="xs">
								{programRegistration.Data.Participants.map((participant, index) => {
									let waitlist = programSessionEnrollments?.find(e => e.ProgramSessionId === programSession.Id && e.ParticipantId === participant.Id)?.WaitList ?? false;//registration?.Data.ProgramSessions.find(p => p.ProgramSessionId === rps.Id).
									let selected = programRegistration.Data.ProgramSessions.find(p => p.ProgramSessionId == programSession.Id && p.Participants.find(a => a.ParticipantId == participant.Id) != null) !== undefined;//enrolledProgramSessionIds.includes(programSession.Id)
									let offWaitList = programRegistration.Data.ProgramSessions.find(p => p.ProgramSessionId == programSession.Id && p.Participants.find(a => a.ParticipantId == participant.Id) != null)?.Participants.find(p => p.ParticipantId === participant.Id)?.WaitList === false;
									let activityParticipant = programRegistration.Data.ProgramSessions.find(p => p.ProgramSessionId == programSession.Id)?.Participants.find(a => a.ParticipantId == participant.Id);
									if (offWaitList)
										waitlist = false;
									return <Stack key={index} spacing={2} align="center">
										<Checkbox label={participant.FirstName} checked={selected} disabled={isDeleted} onChange={(e) => toggleProgramSessionEnrollment(programSessionsForRegistration, programSession.Id, participant.Id, e.target.checked)} mb={3} />
										{/* <Text inline size="sm" mb={9}>{programSession.Id}</Text> */}
										{selected && <>
										{waitlist && !isDeleted && <>
											<Tooltip label="Click to enroll">
												<Badge size="sm" color="orange" sx={{ cursor: 'pointer' }} onClick={() => toggleProgramSessionWaitList(programSession.Id, participant.Id, false)}>Wait List</Badge>
											</Tooltip>
										</>
										}
										{!waitlist && !isDeleted && <Badge size="sm" color="green">Enrolled</Badge>}
										<Stack spacing={0} align="center">
											{activityParticipant?.ActivitySessionIds.map((activitySessionId, index3) => {
												return <Text size="xs" key={index3}>{getProgramActivity(activitySessionId)?.Title}</Text>
											})}
											<SessionActivityEditor participantId={activityParticipant?.ParticipantId ?? ""} programSessionId={programSession.Id} />
										</Stack>
										</>}
									</Stack>
								})}
							</SimpleGrid>
						</Grid.Col>
						{/* <Grid.Col span={12} sm={6}>
							<Grid mt="sm">
								<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Date:</Text></Grid.Col>
								<Grid.Col span={9} sm={9} py={0}><Text size="sm">{moment(rps.StartDate).format("L")}</Text></Grid.Col>
								<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Time:</Text></Grid.Col>
								<Grid.Col span={9} sm={9} py={0}><Text size="sm">{moment(rps.StartDate).format("h:mma")} - {moment(rps.EndDate).format("h:mma")}</Text></Grid.Col>
							</Grid>

						</Grid.Col> */}
					</Grid>
					
					{/* </>} */}
					{/* {moment(programSession.StartDate).isSame(moment(programSession.EndDate), "date") === false && 
					<Grid mb="xs">
						<Grid.Col span={3} sm={2} py={0}><Text align="right" color="dimmed" size="sm">From:</Text></Grid.Col>
						<Grid.Col span={9} sm={3} py={0}><Text>{moment(programSession.StartDate).format("LLL")}</Text></Grid.Col>
						<Grid.Col span={3} sm={2} py={0}><Text align="right" color="dimmed" size="sm">To:</Text></Grid.Col>
						<Grid.Col span={9} sm={3} py={0}><Text>{moment(programSession.EndDate).format("LLL")}</Text></Grid.Col>
					</Grid>
					} */}
					{/* {participant.Type == "child" && childParticipant?.Birthdate !== null &&
					<Grid mb="xs">
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Date of Birth:</Text></Grid.Col>
						<Grid.Col span={9} sm={4} py={0}><Text>{moment(childParticipant?.Birthdate).format("LL")}</Text></Grid.Col>
						<Grid.Col span={3} sm={2} py={0}><Text align="right" color="dimmed" size="sm">Age:</Text></Grid.Col>
						<Grid.Col span={9} sm={3} py={0}><Text>{getAge(childParticipant!)}</Text></Grid.Col>
					</Grid>
					} */}
					{/* {index < programSessionsForRegistration.length - 1 && <Divider mb="md" />} */}
				</Paper>
			})}
			</SimpleGrid>
			
		</Card>
	</>
}

export default SessionsView