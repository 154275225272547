import { Group, Title, Button, Divider } from "@mantine/core"
import { Link, useParams } from "react-router-dom"
import { ProgramRegistrationListByProgramGroup, RecentProgramRegistrationList } from "../../Components/ProgramRegistrations/ProgramRegistrationList"
import { useProgramGroupsQuery } from "../../api/program.api";
import { linkTitle } from "../../shared/helpers";
import { IconPlus } from "@tabler/icons-react";
import { RoleTemplate } from "../../Components/RoleTemplate";

export interface RegistationPageParams {
	urlProgramGroup: string;
}

const RegistationsHomePage = () => {
	const { urlProgramGroup } = useParams<RegistationPageParams>();
	const { data: programGroups } = useProgramGroupsQuery();

	const programGroup = programGroups?.find(g => linkTitle(g.Title) === urlProgramGroup);

	return <>
		<Group position="apart">
			{programGroup &&
			<Title order={2} mb="sm">Registrations for {programGroup?.Title}</Title>
			}
			{!programGroup &&
			<Title order={2} mb="sm">All Registrations</Title>
			}
			{/* <RoleTemplate role="role.admin">
				<Button component={Link} to="/registrations/new" size="sm" variant="subtle" sx={{ alignSelf: 'flex-start'}} mb="sm" leftIcon={<IconPlus />}>New Registration</Button>
			</RoleTemplate> */}
		</Group>
		<Divider />

		{programGroup &&
		<ProgramRegistrationListByProgramGroup programGroupId={programGroup.Id} />
		}
		{!programGroup &&
		<RecentProgramRegistrationList />
		}
	</>
}

export default RegistationsHomePage