import { Switch, Route } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { AppShell, ColorScheme, ColorSchemeProvider, Container, MantineProvider } from '@mantine/core';
import { Notifications } from "@mantine/notifications";
import { useHotkeys, useLocalStorage } from "@mantine/hooks";
import { QueryClient, QueryClientProvider } from 'react-query';

import { Login } from './Auth/Login';
// import './App.css'
import { HeaderTabsColored } from './Layout/Header';
import Home from './Pages/Home';
import ViewRegistationPage from "./Pages/Registrations/View";
import ViewCalendarPage from "./Pages/Calendar/View";
import ViewCalendarSessionPage from "./Pages/Calendar/Session";
import RegistationsHomePage from "./Pages/Registrations/Index";
import ReportPage from "./Pages/Reports/View";
import SearchPage from "./Pages/Search/View";

import { pdfjs } from 'react-pdf';
import { ModalsProvider } from "@mantine/modals";
import { ErrorBoundary } from "./Components/Shared/ErrorBoundary";
import ErrorBoundaryFallback from "./Components/Shared/ErrorBoundaryFallback";
import NewRegistationPage from "./Pages/Registrations/New";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const App = () => {
	const queryClient = new QueryClient()
	const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
		key: 'mantine-color-scheme',
		defaultValue: 'light',
		getInitialValueInEffect: true,
	});

	const isProduction = process.env.NODE_ENV !== 'development';

	const toggleColorScheme = (value?: ColorScheme) =>
		setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

	useHotkeys([['mod+J', () => toggleColorScheme()]]);

	document.title = import.meta.env.VITE_TITLE;

	const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

	return (
		<ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
			<MantineProvider withGlobalStyles withNormalizeCSS theme={{ 
				colorScheme: colorScheme,
				activeStyles: { transform: 'scale(0.95)' },
				colors: {
					brand: [
						'#e5f8f4',
						'#cce1e0',
						'#b1cbc9',
						'#95b7b3',
						'#79a29e',
						'#5f8984',
						'#486a67',
						'#324c49',
						'#1a2f2c',
						'#001212'
					]  
				},
				// primaryColor: 'brand'
			}}>
				<ModalsProvider>
				<Notifications position="top-right" />
				<QueryClientProvider client={queryClient}>
					<AuthenticatedTemplate>
						<Elements stripe={stripePromise}>
						<AppShell
							fixed
							padding="xs"
							header={<HeaderTabsColored />}
							// footer={
							// 	<Footer height={60} p="md">
							// 	  Application footer
							// 	</Footer>
							//   }
						>
							<Container size="xl">
									<Switch>
								
										<Route path="/" exact component={Home}/>
										<Route path="/calendar" exact component={ViewCalendarPage} />
										<Route path="/calendar/" exact component={ViewCalendarPage} />
										<Route path="/calendar/session/:programSessionId" exact component={ViewCalendarSessionPage} />
										<Route path="/registrations" exact component={RegistationsHomePage} />
										<Route path="/registrations/new" exact component={NewRegistationPage} />
										<Route path="/registrations/view/:registrationId" exact component={ViewRegistationPage} />
										<Route path="/registrations/list/:urlProgramGroup" component={RegistationsHomePage} />
										<Route path="/reports" exact component={ReportPage} />
										<Route path="/reports/:urlReportKey" component={ReportPage} />
										<Route path="/search" exact component={SearchPage} />
										<Route path="/search/:urlQuery" component={SearchPage} />
										{/* <Route path="/forms" exact component={Forms}/>
										<Route path="/forms/:formId" exact component={FormPage} />
										<Route path="/submissions" exact component={Submissions} />
										<Route path="/submissions/form/:formId/submission/:submissionId" exact component={SubmissionDetails} /> */}
								
									</Switch>
							</Container>
						</AppShell>
						</Elements>
					
					</AuthenticatedTemplate>
					<UnauthenticatedTemplate>
						<Login />

					</UnauthenticatedTemplate>
				</QueryClientProvider>
				</ModalsProvider>
			</MantineProvider>
		</ColorSchemeProvider>
	)
}

export default App
