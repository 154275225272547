import { useHistory } from "react-router-dom"
import { Group, Title, Grid, Divider, Button, Text } from "@mantine/core"
import { modals } from '@mantine/modals';
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import _ from "lodash";
import { useProgramRegistrationSaveMutation } from "../../api/programregistration.api";
import { createEmptyProgramRegistration, getParticipantsNames } from "../../functions/programregistration.functions";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import DetailsView from "../../Components/ProgramRegistrations/DetailsView";
import ParticipantsView from "../../Components/ProgramRegistrations/ParticipantsView";
import SessionsView from "../../Components/ProgramRegistrations/SessionsView";
import ParentGuardiansView from "../../Components/ProgramRegistrations/ParentGuardiansView";
import PaymentView from "../../Components/ProgramRegistrations/PaymentView";
import CustomFieldsView from "../../Components/ProgramRegistrations/CustomFieldsView";
import { RoleTemplate } from "../../Components/RoleTemplate"
import { ErrorBoundary } from "../../Components/Shared/ErrorBoundary";
import ErrorBoundaryFallback from "../../Components/Shared/ErrorBoundaryFallback";

export interface NewRegistationPageParams {
	
}

const NewRegistationPage = () => {
	const history = useHistory();
	// const { } = useParams<NewRegistationPageParams>();
	const { programRegistration, setProgramRegistration, isChanged, isValid, setIsLoading, reset } = useProgramRegistrationState();
	const { mutate: saveProgramRegistration, isLoading: isSaving, isError, error } = useProgramRegistrationSaveMutation();
	const participantNames = getParticipantsNames(programRegistration).join(', ');


	const onSave = () => { 
		modals.openConfirmModal({
			title: 'Confirm Save',
			centered: true,
			children: <>
				<Text size="sm">
					Are you sure you want to save this registration?
				</Text>
			</>,
			labels: { confirm: 'Yes, Save Registration', cancel: 'Cancel' },
			confirmProps: { color: 'green' },
			// onCancel: () => console.log('Cancel'),
			onConfirm: () => performSave(),
		});
	}

	const performSave = () => {
		showNotification({
			id: 'programregistration-save',
			loading: true,
			title: 'Saving registration',
			message: 'Please wait while the registration is created',
			autoClose: false,
			// disallowClose: true
		});
		
		saveProgramRegistration(programRegistration!, { 
			onSuccess: (result) => {
				setProgramRegistration(createEmptyProgramRegistration(), false);
				updateNotification({
					id: 'programregistration-save',
					title: 'Success',
					message: 'Registration has been saved',
					color: 'green',
					icon: <IconCheck size={16} />,
					autoClose: 3000
				});
				history.replace({ pathname: `/registrations/view/${result.data.Id}`});
			}, onError: (error) => {
				updateNotification({
					id: 'programregistration-save',
					title: 'Error',
					message: 'An error occurred while trying to save the registration',
					color: 'red',
					icon: <IconX size={16} />,
					autoClose: 3000
				});
			}
		})
	}

	const onCancel = () => {
		history.goBack();
	}

	return <>
		<ErrorBoundary fallback={<ErrorBoundaryFallback />}>
		<RoleTemplate role="role.user.readonly" noAccess={<>You don't have access to this.</>}>
			<Group position="apart">
				<Title order={2} mb="sm">
					New Registration
					{participantNames !== "" && <>
						<span>  for: </span>
						<Text span variant="gradient" sx={{ lineHeight: "unset" }}>{participantNames}</Text>
					</>}
				</Title>

				<Group spacing="xs" mb="xs" noWrap sx={{ alignSelf: 'flex-start' }}>
					{isChanged && <>
						<Button onClick={onSave} variant="filled" disabled={isSaving || !isValid()} hidden={!isChanged} leftIcon={<IconCheck />}>Save Registration</Button>
						<Button onClick={onCancel} variant="light" disabled={isSaving} hidden={!isChanged} leftIcon={<IconX />}>Discard</Button>
					</>}
				</Group>
			</Group>
			<Divider />

			<Grid mt="xs" mb="md">
				<Grid.Col xs={12} sm={6}>
					<DetailsView />
					<ParentGuardiansView />
					<ParticipantsView />
				</Grid.Col>
				<Grid.Col xs={12} sm={6}>
					<SessionsView />
					<CustomFieldsView />
					<PaymentView />
				</Grid.Col>
			</Grid>

		</RoleTemplate>
		<RoleTemplate role="role.admin">
			<small>
			<pre>
				{JSON.stringify(programRegistration, null, 2)}
			</pre>
			</small>
		</RoleTemplate>
		</ErrorBoundary>
	</>
}

export default NewRegistationPage